
// @ts-nocheck


export const localeCodes =  [
  "pl"
]

export const localeLoaders = {
  "pl": [{ key: "../locale/langs/pl-PL.json", load: () => import("../locale/langs/pl-PL.json" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_ui_locale_langs_pl_PL_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../locale/vue-i18n.ts?hash=20977ec1&config=1" /* webpackChunkName: "__locale_vue_i18n_ts_20977ec1" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./locale/vue-i18n.ts",
  "locales": [
    {
      "code": "pl",
      "iso": "pl-PL",
      "lang": "pl",
      "title": "Polska / Poland",
      "storeId": "default",
      "storePrefix": "pl_pl",
      "country": "PL",
      "currency": "PLN",
      "freeShippingThreshold": 200,
      "magentoMainCategory": "2",
      "specificGeolocationCountryCodes": [],
      "files": [
        "locale/langs/pl-PL.json"
      ]
    }
  ],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locale/langs/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "pl",
    "iso": "pl-PL",
    "lang": "pl",
    "title": "Polska / Poland",
    "storeId": "default",
    "storePrefix": "pl_pl",
    "country": "PL",
    "currency": "PLN",
    "freeShippingThreshold": 200,
    "magentoMainCategory": "2",
    "specificGeolocationCountryCodes": [],
    "files": [
      {
        "path": "locale/langs/pl-PL.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
